import { useMemo } from 'react'
import { ApolloClient, ApolloQueryResult, useQuery } from '@apollo/client'
import { Maybe } from 'graphql/jsutils/Maybe'
import { slugs } from '@/constants'
import { ContentfulClientOptions, getContentfulClient, getWebClient } from '@/services/ApolloClient'
import {
  getAllProjectsQuery,
  GetEpisodeResponse,
  getProjectAndEpisodeQuery,
  getProjectMetaQuery,
  GetVideoSeoMetaResponse,
  Project,
  ProjectType,
  Season,
} from '@/services/ProjectsService'
import { findEpisodeGuidBySlug } from '@/services/ProjectsService/FriendlyEpisodeSlugHelper'
import {
  GET_AVAILABLE_COLLECTIBLE_OFFERS_BY_PROJECT,
  GET_AVAILABLE_MOMENT,
  GET_EPISODE_BY_GUID_QUERY,
  GET_EPISODE_FROM_MOMENT,
  GET_EPISODE_FROM_MOMENT_WITH_USER,
  GET_FRAME_BY_ID,
  GET_GUILD_ACCESS_PROJECTS,
  GET_LIGHT_PROJECT_FOR_SHARE,
  GET_MOMENT_ID_FROM_STORY,
  GET_PROJECT_CONTENT_LINK_QUERY,
  GET_PROJECT_GUILD_SCORE,
  GET_PROJECT_PUBLIC_INTEREST_INITIATIVES,
  GET_START_WATCHING_QUERY,
  GET_WATCHABLE_EPISODE,
  getContentfulProjectConfig,
  getProjectAndEpisodeQueryForDeepLink,
  getProjectAndFaqQuery,
  getProjectDetailsQuery,
  getProjectQuery,
  getProjectSitemapsQuery,
  getVideoSeoQuery,
  PROJECTS_IN_CURRENT_PHASE,
} from '@/services/ProjectsService/queries'
import {
  Episode,
  GetProjectParams,
  MediaQuery,
  ProjectContentLink,
  ProjectLinkMeta,
  ProjectMeta,
  ProjectsWithSlugs,
  ProjectWithVideosSeoMeta,
  VALID_PROJECT_TYPES,
} from '@/services/ProjectsService/types'
import { GetGuildAccessProjectsQuery } from '@/types/codegen-contentful'
import {
  AvailableCollectibleOffersInput,
  FetchProjectGuildScoreQuery,
  GetAvailableCollectibleOffersByProjectQuery,
  GetAvailableMomentQuery,
  GetEpisodeFromMomentQuery,
  GetEpisodeFromMomentWithUserQuery,
  GetFrameByIdQuery,
  GetLightProjectForShareQuery,
  GetProjectContentLinksQuery,
  GetProjectDetailsQuery,
  GetProjectInterestInitiativesQuery,
  GetProjectInterestInitiativesQueryVariables,
  GetStartWatchingQuery,
  GetWatchableEpisodeQuery,
  GetWatchableEpisodeQueryVariables,
  ProjectsInCurrentPhaseQuery,
  QueryProjectsInCurrentPhaseArgs,
} from '@/types/codegen-federation'
import { isALivestream, isAPitch, isAPreview, isARecap, isASneakPeek, isATeaser, isATorch } from '@/utils/EpisodeUtil'
import { reportErrorToBugsnag, reportWarningToBugsnag } from '@/utils/bugsnag'
import { logger } from '@/utils/logging'
import { isDefined } from '@/utils/types'

export function isValidProjectType(projectType: Maybe<string>): projectType is ProjectType {
  return !!projectType && VALID_PROJECT_TYPES.includes(projectType as ProjectType)
}

export const getAllProjects = async (client: ApolloClient<object>): Promise<ApolloQueryResult<ProjectsWithSlugs>> => {
  return await client.query({
    query: getAllProjectsQuery,
    errorPolicy: 'all',
  })
}

export const getProjectMeta = async (
  { slug }: GetProjectParams,
  client: ApolloClient<object>,
  opts: ContentfulClientOptions,
): Promise<ProjectMeta> => {
  const { data } = await client.query({
    query: getProjectMetaQuery,
    variables: {
      slug,
    },
    errorPolicy: 'all',
  })

  const contentfulClient = getContentfulClient({
    locale: opts.locale ?? 'en',
    preview: opts.preview,
  })

  let contentfulProjectData

  try {
    const { data: contentfulData } = await contentfulClient.query({
      query: getContentfulProjectConfig,
      variables: { projectSlug: slug },
      fetchPolicy: 'no-cache',
    })

    contentfulProjectData = contentfulData?.projectCollection?.items?.[0]
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Failure to get data from Contentful', e)
  }

  return {
    ...data.project,
    ...contentfulProjectData,
  }
}

export const getProjectAbout = async (
  { slug }: GetProjectParams,
  client: ApolloClient<object>,
  opts?: ContentfulClientOptions,
): Promise<ProjectMeta> => {
  const { data } = await client.query({
    query: getProjectMetaQuery,
    variables: {
      slug,
    },
    errorPolicy: 'all',
  })

  const contentfulClient = getContentfulClient({
    locale: opts?.locale ?? 'en',
    preview: opts?.preview,
  })

  let contentfulProjectData
  let contentfulFaqData
  let contentfulDownloadableData

  try {
    const { data: contentfulData } = await contentfulClient.query({
      query: getProjectAndFaqQuery,
      variables: { projectSlug: slug, preview: opts?.preview },
      fetchPolicy: 'no-cache',
    })

    contentfulProjectData = contentfulData?.projectCollection?.items?.[0]
    contentfulFaqData = contentfulData?.faqCollection?.items
    contentfulDownloadableData = contentfulData?.downloadableCollection?.items
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Failure to get data from Contentful', JSON.stringify(e))
  }

  return {
    ...data.project,
    ...contentfulProjectData,
    faqs: contentfulFaqData ?? [],
    downloadables: contentfulDownloadableData ?? [],
  }
}

interface EpisodeDetailsForDeepLink {
  project: {
    projectType?: ProjectType
    discoveryPosterLandscapeCloudinaryPath?: string
    name?: string
    slug?: string
    seasons?: {
      episodes?: {
        guid?: string
        projectSlug?: string
      }[]
    }[]
  }
  episode: {
    episodeNumber?: number
    seasonNumber?: number
    description?: string
    subtitle?: string
    projectSlug?: string
  }
  backgroundImageCloudinaryPath: string | null
}

export const getEpisodeDetailsForDeepLink = async (
  { guid, projectSlug }: MediaQuery,
  client: ApolloClient<object>,
): Promise<EpisodeDetailsForDeepLink> => {
  let result
  try {
    const { data } = await client.query({
      query: getProjectAndEpisodeQueryForDeepLink,
      variables: {
        guid,
        projectSlug,
      },
      errorPolicy: 'all',
    })

    result = data
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn(`Episode could not be looked up by guid=${guid}`)
  }

  if (!result?.episode) {
    const updatedGuid = await findEpisodeGuidBySlug(client, guid, projectSlug)

    if (updatedGuid) {
      const { data } = await client.query({
        query: getProjectAndEpisodeQueryForDeepLink,
        variables: {
          guid: updatedGuid,
          projectSlug,
        },
        errorPolicy: 'all',
      })
      result = data
    }
  }

  if (projectSlug !== result?.episode?.projectSlug) {
    throw Error('Episode does not belong to Project')
  }

  return result
}

export const getEpisode = async (
  { guid, projectSlug }: MediaQuery,
  client: ApolloClient<object>,
): Promise<GetEpisodeResponse> => {
  let result
  try {
    const { data } = await client.query({
      query: getProjectAndEpisodeQuery,
      variables: {
        guid,
        projectSlug,
      },
      errorPolicy: 'all',
    })

    result = data
  } catch (e) {
    reportErrorToBugsnag(`Error getProjectAndEpisodeQuery guid=${guid} projectSlug=${projectSlug} e=${e?.toString()}`)
  }

  if (!result?.episode) {
    const updatedGuid = await findEpisodeGuidBySlug(client, guid, projectSlug)

    if (updatedGuid) {
      const { data } = await client.query({
        query: getProjectAndEpisodeQuery,
        variables: {
          guid: updatedGuid,
          projectSlug,
        },
        errorPolicy: 'all',
      })
      result = data
    }
  }

  if (projectSlug !== result?.episode?.projectSlug) {
    throw Error(
      `Episode (${guid}) does not belong to Project (${projectSlug}) and episode project slug ${result?.episode?.projectSlug} do not match.`,
    )
  }

  return result
}

export const getEpisodeByGuid = async (
  { guid }: { guid: string },
  client: ApolloClient<object>,
): Promise<GetEpisodeResponse> => {
  let result
  try {
    const { data } = await client.query({
      query: GET_EPISODE_BY_GUID_QUERY,
      variables: {
        guid,
      },
      errorPolicy: 'all',
    })

    result = data
  } catch (e) {
    reportErrorToBugsnag(`Error getEpisodeByGuidQuery guid=${guid} e=${e?.toString()}`)
  }

  return result.episode
}

export const getLightProjectForShare = async (
  { slug }: { slug: string },
  client: ApolloClient<object>,
): Promise<GetLightProjectForShareQuery['project']> => {
  let result
  try {
    const { data } = await client.query({
      query: GET_LIGHT_PROJECT_FOR_SHARE,
      variables: {
        projectSlug: slug,
      },
      errorPolicy: 'all',
    })

    result = data
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn(`Project could not be looked up by slug=${slug}`)
  }

  return result?.project
}

export const getEpisodeFromMomentWithUser = async (
  { momentId, shareUserId }: { momentId: string; shareUserId: string },
  client: ApolloClient<object>,
): Promise<GetEpisodeFromMomentWithUserQuery> => {
  let result
  try {
    const { data } = await client.query({
      query: GET_EPISODE_FROM_MOMENT_WITH_USER,
      variables: {
        momentId,
        shareUserId,
      },
      errorPolicy: 'all',
    })

    result = data
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn(`Episode could not be looked up by momentId=${momentId}`)
  }

  if (!result?.moment?.episode) {
    throw Error('Moment does not belong to an Episode')
  }

  if (!result?.moment?.episode?.projectSlug) {
    throw Error('Moments Episode does not belong to a Project')
  }

  return result
}

export type StartWatchingProject = NonNullable<NonNullable<GetStartWatchingQuery>['projects']>[number]

export async function getStartWatchingList(): Promise<StartWatchingProject[]> {
  const client = getWebClient()

  try {
    const response = await client.query<GetStartWatchingQuery>({
      query: GET_START_WATCHING_QUERY,
      errorPolicy: 'all',
    })

    return isDefined(response.data.projects) ? response.data.projects?.filter(isDefined) : []
  } catch (error) {
    logger().error('Error fetching start watching list', { error })
    return []
  }
}

export const getMomentFromStory = async (
  { userStoryId }: { userStoryId: string },
  client: ApolloClient<object>,
): Promise<{ moment: GetEpisodeFromMomentQuery['moment'] | null }> => {
  let result
  try {
    const { data } = await client.query({
      query: GET_MOMENT_ID_FROM_STORY,
      variables: {
        userStoryId,
      },
      errorPolicy: 'all',
    })

    result = data
  } catch (e) {
    reportErrorToBugsnag(`Moment could not be looked up by storyId=${userStoryId}`)
  }

  const momentId = result?.userStory?.momentId || null

  let moment

  try {
    const response = await getEpisodeFromMoment({ momentId }, client)
    moment = response.moment
  } catch {
    reportErrorToBugsnag("Couldn't find realted moment for story")
  }

  return {
    moment,
  }
}

export const getAvailableCollectibleOffersByProject = async (
  { input }: { input: AvailableCollectibleOffersInput },
  client: ApolloClient<object>,
): Promise<GetAvailableCollectibleOffersByProjectQuery['availableCollectibleOffers']> => {
  let result

  try {
    const { data } = await client.query({
      query: GET_AVAILABLE_COLLECTIBLE_OFFERS_BY_PROJECT,
      variables: {
        input,
      },
      errorPolicy: 'all',
    })

    result = data.availableCollectibleOffers
  } catch (e) {
    reportWarningToBugsnag('Could not find available collectible offers on shop page', e)
  }

  return result
}

export const getAvailableMoment = async (
  { momentId }: { momentId: number },
  client: ApolloClient<object>,
): Promise<{ moment: GetAvailableMomentQuery['moment'] | null }> => {
  let result

  try {
    const { data } = await client.query({
      query: GET_AVAILABLE_MOMENT,
      variables: {
        momentId,
      },
      errorPolicy: 'all',
    })

    result = data
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('Could not find moment', e)
  }

  return result
}

export const getEpisodeFromMoment = async (
  { momentId }: { momentId: string },
  client: ApolloClient<object>,
): Promise<{ moment: GetEpisodeFromMomentQuery['moment'] | null }> => {
  let result

  try {
    const { data } = await client.query({
      query: GET_EPISODE_FROM_MOMENT,
      variables: {
        momentId,
      },
      errorPolicy: 'all',
    })

    result = data
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn(`Episode could not be looked up by momentId=${momentId}`)
  }

  if (!result?.moment?.episode) {
    throw Error('Moment does not belong to an Episode')
  }

  if (!result?.moment?.episode?.projectSlug) {
    throw Error('Moments Episode does not belong to a Project')
  }

  return {
    moment: result.moment,
  }
}

export const getFrameById = async (
  { frameId }: { frameId: string },
  client: ApolloClient<object>,
): Promise<{ frame: GetFrameByIdQuery['frame'] | null }> => {
  let result
  try {
    const { data } = await client.query({
      query: GET_FRAME_BY_ID,
      variables: {
        frameId,
      },
      errorPolicy: 'all',
    })
    result = data
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('Frame could not be found')
  }

  return {
    frame: result.frame,
  }
}

export const getVideoMetaData = async (
  { guid, projectSlug }: MediaQuery,
  client: ApolloClient<object>,
): Promise<GetVideoSeoMetaResponse> => {
  const { data } = await client.query<ProjectWithVideosSeoMeta>({
    query: getVideoSeoQuery,
    variables: {
      id: guid,
      guid,
      projectSlug,
    },
    errorPolicy: 'all',
  })

  const video =
    data?.project?.videos?.find?.((video) => video.guid === guid) ??
    data?.project?.trailers?.find?.((trailer) => trailer.id === guid)

  return {
    projectName: data?.project?.name,
    logoCloudinaryPath: data?.project?.logoCloudinaryPath,
    projectSlug,
    public: data?.project?.public,
    video,
  }
}

export const getProjectSitemaps = async (client: ApolloClient<object>): Promise<ProjectLinkMeta[]> => {
  const { data } = await client.query({
    query: getProjectSitemapsQuery,
    errorPolicy: 'all',
  })

  return data.projects
}

export const getPreferredSeason = (project: Project) => {
  const seasons = project.seasons || []

  if (seasons.length === 0) {
    return
  }

  // There are cases where we get a continue watching from a different theater, for reasons
  if (project.continueWatching?.seasonId && project.slug) {
    const found = seasons.find(
      (season: Season) =>
        season.id === project.continueWatching?.seasonId && project.continueWatching.projectSlug === project.slug,
    )
    return found ?? seasons[0]
  } else if (project.slug === slugs.theChosen) {
    return seasons[seasons.length - 1]
  } else {
    return seasons[0]
  }
}

export const getSeasonByNameOrIndex = ({
  seasonNameOrIndex,
  project,
}: {
  seasonNameOrIndex: string
  project: Project
}) => {
  const seasons = project.seasons || []

  if (seasons.length === 0) {
    return
  }

  if (seasonNameOrIndex) {
    const seasonByName = seasons.find((current) => current.name?.includes(seasonNameOrIndex))
    const seasonIndex = parseInt(seasonNameOrIndex) - 1

    if (seasonByName) {
      return seasonByName
    } else if (!isNaN(seasonIndex) && seasons[seasonIndex]) {
      return seasons[seasonIndex]
    } else {
      return seasons[0]
    }
  }
}

export const getProjectContentLinks = async (): Promise<ProjectContentLink[]> => {
  const client = getWebClient()

  try {
    const { data } = await client.query<GetProjectContentLinksQuery>({
      query: GET_PROJECT_CONTENT_LINK_QUERY,
      errorPolicy: 'all',
    })

    const now = new Date()

    return (
      data.projects?.filter((project): project is ProjectContentLink => {
        if (!project || !project.id || !project.public) return false

        return hasAvailableEpisode(project, now)
      }) || []
    )
  } catch (error) {
    logger().error('Error fetching project content links', { error })
    return []
  }
}

export const getProjectsInCurrentPhase = async (
  variables: QueryProjectsInCurrentPhaseArgs,
  client: ApolloClient<object>,
) => {
  const { data } = await client.query<ProjectsInCurrentPhaseQuery>({
    query: PROJECTS_IN_CURRENT_PHASE,
    variables,
    errorPolicy: 'all',
  })

  const projects = data.projectsInCurrentPhase || []

  return projects.filter(isDefined)
}

export const getProjectDetails = async ({ slug }: { slug: string }, client: ApolloClient<object>) => {
  const { data } = await client.query<GetProjectDetailsQuery>({
    query: getProjectDetailsQuery,
    variables: { slug },
    errorPolicy: 'all',
  })

  return data?.project
}

export const getGuildEarlyAccessProjects = async (opts?: ContentfulClientOptions) => {
  const client = getContentfulClient(opts)
  const { data } = await client.query<GetGuildAccessProjectsQuery>({
    query: GET_GUILD_ACCESS_PROJECTS,
  })

  return data.projectCollection?.items
}

export const getProjectInterestInitiatives = async (variables: GetProjectInterestInitiativesQueryVariables) => {
  const client = getWebClient()

  const { data } = await client.query<GetProjectInterestInitiativesQuery>({
    query: GET_PROJECT_PUBLIC_INTEREST_INITIATIVES,
    variables,
    errorPolicy: 'all',
  })

  return data.project
}

export const getWatchProjectEarlyAccess = async (
  projectSlug: string,
  projectMeta: ProjectMeta,
  client: ApolloClient<object>,
) => {
  let result

  try {
    const { data } = await client.query({
      query: getProjectQuery,
      variables: { slug: projectSlug, includeSeasons: projectMeta?.seasons?.length > 0, includePrerelease: true },
      errorPolicy: 'all',
    })

    result = data.project
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('Could not fetch project about', e)
  }

  return result
}

export const getProjectGuildScore = async (
  projectSlug: string,
): Promise<{
  guildScore: number | undefined
}> => {
  const client = getWebClient()
  const { data: projectV2Data } = await client.query<FetchProjectGuildScoreQuery>({
    query: GET_PROJECT_GUILD_SCORE,
    variables: { projectSlug },
    errorPolicy: 'all',
  })

  return {
    guildScore: projectV2Data.projectV2BySlug?.highestScore ?? undefined,
  }
}

export const getWatchableEpisodeByGuid = async (
  guid: string,
  client: ApolloClient<object>,
): Promise<{
  watchableReasons: string[] | undefined
}> => {
  try {
    const { data } = await client.query<GetWatchableEpisodeQuery>({
      query: GET_WATCHABLE_EPISODE,
      variables: { guid },
      errorPolicy: 'all',
    })
    if (data?.getContent?.__typename === 'ContentEpisode') {
      return {
        watchableReasons: data.getContent.watchableReasons?.filter(isDefined).map((reason) => reason.__typename),
      }
    }
    return { watchableReasons: undefined }
  } catch (error) {
    logger().error(`Error fetching watchable episode by GUID: ${guid}`, error)
    return { watchableReasons: undefined }
  }
}

export const useGetWatchableEpisodeByGuid = (guid: string, isLoggedIn: boolean) => {
  const client = getWebClient()
  const { data } = useQuery<GetWatchableEpisodeQuery, GetWatchableEpisodeQueryVariables>(GET_WATCHABLE_EPISODE, {
    client,
    variables: { guid: guid },
    skip: !isLoggedIn,
    errorPolicy: 'all',
  })
  const episode = data?.getContent?.__typename === 'ContentEpisode' ? data.getContent : undefined
  return useMemo(() => ({ watchableEpisode: episode }), [episode])
}

function shouldNotShowEpisodeAsWatchable({
  name,
  slug,
  subtitle,
}: NonNullable<Pick<Episode, 'name' | 'slug' | 'subtitle'>>): boolean {
  return (
    isAPreview({ name }) ||
    isASneakPeek({ name, slug }) ||
    isATorch({ subtitle }) ||
    isATeaser({ name, subtitle }) ||
    isAPitch({ name }) ||
    isARecap({ name }) ||
    isALivestream({ name })
  )
}

export const hasAvailableEpisode = (project: ProjectContentLink, now: Date): boolean => {
  return Boolean(
    project?.seasons?.some((season) =>
      season?.episodes?.some((episode) => {
        if (
          !project ||
          !project.id ||
          !project.slug ||
          !season ||
          !episode ||
          !episode.guid ||
          !episode.name ||
          !episode.slug ||
          !episode.subtitle ||
          episode.isTrailer
        )
          return false

        if (
          shouldNotShowEpisodeAsWatchable({
            name: episode.name,
            slug: episode.slug,
            subtitle: episode.subtitle,
          })
        )
          return false

        const guildAccessDate = episode?.earlyAccessDate ? new Date(episode.earlyAccessDate) : null
        const publiclyAvailableDate = episode?.publiclyAvailableDate ? new Date(episode.publiclyAvailableDate) : null

        return (guildAccessDate && guildAccessDate <= now) || (publiclyAvailableDate && publiclyAvailableDate <= now)
      }),
    ),
  )
}

export const getIsGeoblocked = (projectData: Project): boolean => {
  return projectData?.seasons?.some((season) =>
    season.episodes?.some((episode) => episode?.unavailableReason?.includes('GEOBLOCKED')),
  )
}
